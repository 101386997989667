import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { inject, observer } from "mobx-react";
import queryString from "query-string";
import { withRouter } from "react-router";
import { GlobalProps } from "../app/App";
import { ErrorCode } from "../../enums/error-code.enum";
import { RouteUtil } from "../../utils/route-utils";
import { Routes } from "../../routes";
import { Constants } from "../../constants";
import AuthContainerNew from "../shared/auth-container/AuthContainerNew";
import SwitchLanguageNew from "../shared/SwitchLanguage.tsx/SwitchLanguageNew";
import BrandInput from "../shared/CustomComponents/BrandInput";
import { BrandButton } from "../shared/CustomComponents/BrandButton";
import { PromotionalScreens } from "../../enums/promotional-screens.enum";
import { CommonLanguagesContent } from "../../languages/CommonLanguagesContent";
import CenterLoader from "../shared/CenterLoader/CenterLoader";
import { RegisterPageLanguagesContent } from "../../languages/RegisterPageLanguagesContent";
import { Languages, LanguagesEnumUtils } from "../../enums/languages.enum";
import FormikReCAPTCHA, {
  FormikReCAPTCHARef,
} from "../shared/CustomComponents/FormikReCAPTCHA/FormikReCAPTCHA";
import { SvgIconType } from "../shared/svgIconComponent/svgIcons.list";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleSSOButton from "../GoogleSSOButton";
import LinkedInSSOButton from "../LinkedInSSOButton";
import { SSOPROVIDER } from "../../enums/SSO.enum";
import { message } from "antd";
import { useHistory } from "react-router-dom";

type Props = GlobalProps;
const LoginNew: React.FC<Props> = (props: Props) => {
  const [invalidLoginState, setInvalidLoginState] = React.useState("");
  const [locale, setlocale] = useState(props.store!.currentLocale);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>();
  const [showCaptchaVerification, setShowCaptchaVerification] =
    React.useState<boolean>();
  const recaptchaRef = useRef<FormikReCAPTCHARef>(null);

  const history = useHistory();

  const isVietnameseReport = locale === Languages.VIETNAMESE;

  const commonLanguageContent = CommonLanguagesContent.commonContent[locale];
  const regStep1 = RegisterPageLanguagesContent.RegisterStep1Content[locale];
  const language = LanguagesEnumUtils.getDimensionLanguageFromLocale(locale);
  const store = props.store!.user;
  const testStripeKey = queryString.parse(
    props.location.search,
  ).test_stripe_key;
  const [promotionalImage, setPromotionalImage] = useState<string>();

  const promotionalImages = props.store!.promotionalImagesStore;

  if (testStripeKey) {
    localStorage.setItem(
      Constants.APP_TEST_STRIPE_KEY,
      testStripeKey as string,
    );
  }

  const redirectToForgotPasswordWithDelay = (email: string) => {
    setTimeout(() => {
      history.push(RouteUtil.getUrl(Routes.forgotPassword) + `?email=${email}`);
    }, 2000);
  };

  useEffect(() => {
    setlocale(props.store!.currentLocale);
  }, [props.store!.currentLocale]);

  useEffect(() => {
    if (!promotionalImages.isLoaded && !promotionalImages.isLoading) {
      promotionalImages.fetchPromotionalImages();
    }
  }, []);

  useEffect(() => {
    if (
      promotionalImages.promotionalImages &&
      promotionalImages.promotionalImages.length
    ) {
      promotionalImages.promotionalImages.every((item) => {
        if (item.type === PromotionalScreens.LOGIN) {
          setPromotionalImage(item.image_url);
          return false;
        }

        return true;
      });
    }
  }, [promotionalImages.promotionalImages]);

  useEffect(() => {
    if (sessionStorage.getItem("sessionError")) {
      setInvalidLoginState(sessionStorage.getItem("sessionError") || "");
      sessionStorage.removeItem("sessionError");
    }

    if (localStorage.getItem("captchaOnLoginPage") === "true") {
      setShowCaptchaVerification(true);
    }
  }, []);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required("Email is required"),
    password: yup.string().required("Password is required"),
    recaptchaCode: yup
      .string()
      .nullable()
      .when([], {
        is: () => {
          return showCaptchaVerification;
        },
        then: yup.string().required("Please complete the CAPTCHA to proceed."),
        otherwise: yup.string().nullable(),
      }),
  });
  return (
    <>
      {isSubmitting && <CenterLoader />}
      {promotionalImages.isLoading ? (
        <CenterLoader />
      ) : (
        <AuthContainerNew imageSrc={promotionalImage}>
          <Formik
            initialValues={{ email: "", password: "", recaptchaCode: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              setIsSubmitting(true);
              if (!showCaptchaVerification) {
                // @ts-ignore
                delete values.recaptchaCode;
              }

              window.posthog.capture("user_tried_to_login", {
                email: values.email,
              });

              return store
                .login(values)
                .then((res) => {
                  localStorage.removeItem("captchaOnLoginPage");
                  store.setLoggedInUser(res);
                  props.history.push(RouteUtil.getUrl(Routes.home));
                  window.posthog.identify(res.id, {
                    email: res.email,
                    name: res.first_name + " " + res.last_name,
                  });
                })
                .catch((err: any) => {
                  // Note: Reset captcha after any error
                  recaptchaRef.current?.reset();

                  let errorMessage = "";

                  if (
                    err?.code === ErrorCode.CAPTCHA_REQUIRED ||
                    err?.meta?.showCaptcha
                  ) {
                    localStorage.setItem("captchaOnLoginPage", "true");
                    errorMessage = "CAPTCHA_REQUIRED";
                    setShowCaptchaVerification(true);
                  }

                  if (err && err.code === ErrorCode.UNRECOGNIZED_EMAIL) {
                    setInvalidLoginState(
                      `Sorry, we don't recognise that email address:`,
                    );
                    errorMessage = `Sorry, we don't recognise that email address:`;
                    return;
                  }
                  if (err && err.code === ErrorCode.INCORRECT_PASSWORD) {
                    setInvalidLoginState(
                      `Sorry, there seems to be a problem with that password:`,
                    );
                    errorMessage = `Sorry, there seems to be a problem with that password:`;
                    return;
                  }
                  if (err && err.code === ErrorCode.UNPROCESSED_PAYMENT) {
                    setInvalidLoginState(`User has not completed payment!`);
                    errorMessage = `User has not completed payment!`;
                    return;
                  }
                  if (err && err.code === ErrorCode.EMAIL_ALREADY_EXIST) {
                    setInvalidLoginState(
                      `It looks like you have an account with us. Please set up your password.`,
                    );
                    redirectToForgotPasswordWithDelay(values.email);
                    errorMessage = `It looks like you have an account with us. Please set up your password.`;
                    return;
                  }
                  if (err)
                    setInvalidLoginState(
                      err.message || "Something went wrong!",
                    );
                  errorMessage = "OTHER";

                  window.posthog.capture("user_tried_to_login_failed", {
                    email: values.email,
                    error: errorMessage,
                  });
                })
                .finally(() => setIsSubmitting(false));
            }}
          >
            {(formikProps) => {
              return (
                <form
                  className={`flex flex-col items-center  justify-between w-[77%] mx-auto mt-20 ${
                    isVietnameseReport ? "font-roboto" : "font-poppins"
                  } `}
                  onSubmit={formikProps.handleSubmit}
                >
                  <div
                    className={
                      "w-full flex flex-col items-center justify-between h-full"
                    }
                  >
                    <div className={"flex items-center"}>
                      <span className={"text-45px font-medium "}>
                        Welcome&nbsp;to
                      </span>
                      &nbsp;
                      <img
                        alt="logo"
                        src={require("../../assets/logo.svg")?.default}
                        className="w-36 h-12"
                      />
                    </div>
                    <div className={"ml-auto w-32 mt-4 "}>
                      <SwitchLanguageNew />
                    </div>
                    <div
                      className={`text-center text-13px text-brandDarkSlateBlue w-4/5 mt-2 mb-0 ${
                        invalidLoginState.length ? "text-red-600" : "hidden"
                      }`}
                    >
                      {invalidLoginState}
                    </div>

                    <div className="w-full mt-4">
                      <div className={"flex flex-col space-y-4 w-full"}>
                        <BrandInput
                          name={"email"}
                          label={commonLanguageContent.email}
                          placeholder={
                            commonLanguageContent.email_name_placeholder
                          }
                        />
                        <BrandInput
                          name={"password"}
                          label={commonLanguageContent.password}
                          type={"password"}
                          placeholder={regStep1.password_placeholder}
                        />
                        {showCaptchaVerification && (
                          <div>
                            <FormikReCAPTCHA
                              name={"recaptchaCode"}
                              ref={recaptchaRef}
                              locale={locale}
                            />
                          </div>
                        )}
                        <div className="flex justify-end">
                          <a
                            className={"underline font-medium text-brandGrey"}
                            onClick={() => {
                              props.history.push(
                                RouteUtil.getUrl(Routes.forgotPassword),
                              );
                            }}
                          >
                            {commonLanguageContent.forgotPassword}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className={"w-full flex flex-col items-center mb-8"}>
                      <BrandButton
                        type="submit"
                        children={commonLanguageContent.signIn}
                        className={"w-full"}
                        disabled={isSubmitting}
                      />
                      {/* <span className="mt-4 text-brandDarkBlue2 font-medium text-lg">
                        Or
                      </span>
                      <GoogleOAuthProvider
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
                      >
                        <GoogleSSOButton
                          children={commonLanguageContent.continue_with_google}
                          className={"w-full mt-4"}
                          disabled={isSubmitting}
                          leadingSvgIcon={SvgIconType.googleLogo}
                          leadingSvgIconClassname="mr-4"
                          onSucess={(codeResponse: { code: string }) => {
                            setIsSubmitting(true);
                            store
                              .loginSso(
                                codeResponse.code,
                                SSOPROVIDER.GOOGLE,
                                language,
                              )
                              ?.then(() => setIsSubmitting(false))
                              .catch((err) => message.error(err.message))
                              .finally(() => setIsSubmitting(false));
                          }}
                          onError={() => null}
                        />
                      </GoogleOAuthProvider>
                      <LinkedInSSOButton
                        children={commonLanguageContent.continue_with_linkedin}
                        className={"w-full mt-4"}
                        disabled={isSubmitting}
                        leadingSvgIcon={SvgIconType.linkedInIcon}
                        leadingSvgIconClassname="mr-4 h-6 w-6"
                        onSucess={(code) => {
                          setIsSubmitting(true);
                          store
                            .loginSso(code, SSOPROVIDER.LINKEDIN, language)
                            ?.then((res) => res)
                            .catch((err) => message.error(err.message))
                            .finally(() => setIsSubmitting(false));
                        }}
                        onError={() => null}
                      /> */}
                      <a
                        href={"https://www.aqai.io/company/terms-of-services"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={
                          "text-brandGrey underline mb-3 mt-4  font-medium"
                        }
                      >
                        <span>{commonLanguageContent.terms}</span> |{" "}
                        <span className={""}>
                          {commonLanguageContent.privacy}
                        </span>
                      </a>
                      <p className={"text-brandDarkBlue text-center"}>
                        {commonLanguageContent.accountNotPresent}{" "}
                        <a
                          className={
                            "underline font-medium text-brandDarkBlue transition-none"
                          }
                          href="/signup/AQme"
                        >
                          {commonLanguageContent.signUp}
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </AuthContainerNew>
      )}
    </>
  );
};

export default inject("store")(withRouter(observer(LoginNew)));
